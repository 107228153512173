import { defineStore } from 'pinia';

type State = {
  formSubmitted: boolean;
};

export const useContactStore = defineStore('contact', {
  state: (): State => ({
    formSubmitted: false,
  }),
  actions: {
    setFormSubmitted(value: boolean) {
      this.formSubmitted = value;
    },
  },
});
